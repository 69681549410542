import { Box, Card, Typography } from '@mui/material';
import PricingCards from './PricingCards';
import { selectCurrentUser } from 'store/app/app-selectors';
import { useSelector } from 'react-redux';
import SubscriptionPlan from 'features/Payment/SubscriptionPopup/SubscriptionPlan';

const BillingSubscription = () => {
	const user = useSelector(selectCurrentUser);

	return (
		<Box>
			<Typography variant="h5" fontWeight={700}>
				Billing & Subscription
			</Typography>
			<Typography variant="subtitle1" color={'var(--Medium-Grey)'}>
				Keep track of your subscription details, update your billing information
				and control your account payments.
			</Typography>
			{user.renewed_on && (
				<Box sx={{ mt: 5 }}>
					<PricingCards />
				</Box>
			)}
			{!user.renewed_on && (
				<Card
					variant="outlined"
					sx={{
						display: 'flex',
						flexDirection: 'column',
						padding: '20px',
						alignItems: 'flex-start',
						marginTop: '20px',
					}}
				>
					<Typography variant="h5">Available Plans</Typography>
					<Typography
						variant="subtitle1"
						color={'var(--Medium-Grey)'}
						sx={{ mb: 3 }}
					>
						Currently you do not have any active subscription, you can subscribe
						using available plans
					</Typography>
					<SubscriptionPlan />
				</Card>
			)}
		</Box>
	);
};

export default BillingSubscription;
