import { MouseEvent, SetStateAction, useMemo, useState } from 'react';
import { DropzoneRootProps, useDropzone } from 'react-dropzone';
import { ReactComponent as InvalidFileIcon } from 'assets/svgs/InvalidFile.svg';
import { BillingService } from 'api/services/billing.service';
import { useNotifications } from 'components/Notifications/useNotifications';
import { NotificationSeverity } from 'components/Notifications/Notification/Notification';
import { IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

const baseStyle = {
	flex: 1,
	display: 'flex',
	flexDirection: 'column',
	color: '#4285F4',
	outline: 'none',
	height: '35px',
	cursor: 'pointer',
	justifyContent: 'space-between',
};

const focusedStyle = {
	border: 'none',
};

const acceptStyle = {
	border: 'none',
};

const rejectStyle = {
	backgroundColor: '#FED7D7',
	border: '2px solid #EA4335',
};

const UploadBill = ({
	setLoading,
	setData,
	cloud,
	setShowBill,
}: {
	setLoading: React.Dispatch<SetStateAction<boolean>>;
	setShowBill: React.Dispatch<SetStateAction<boolean>>;
	setData: React.Dispatch<SetStateAction<any>>;
	cloud: string;
}) => {
	const [file, setFile] = useState<File[] | null>(null);
	const [rejectedFiles, setRejectedFiles] = useState<File[] | null>(null);
	const notification = useNotifications();

	// Determine the accepted file types based on the cloud ID
	const acceptedFileTypes = useMemo(() => {
		return { 'text/csv': ['.csv'] };
	}, []);

	// Determine the supported file description based on the cloud ID
	const supportedFileDescription = useMemo(() => {
		return 'File supported: .CSV';
	}, []);

	/**
	 * Handles the processing of the selected file.
	 * If a file is selected, it reads the file content, parses it as JSON,
	 * sets the project ID, checks if the config file is valid, and creates a connector.
	 * If the config file is not valid, it displays an error message.
	 */
	const handleProcessBillFile = (file: File[] | null) => {
		if (!file || file.length === 0) {
			return;
		}

		setFile(file);
		if (file?.length) {
			const selectedFile = file[0];
			const reader = new FileReader();
			reader.onload = () => {
				const fileContent = reader.result as string;
				if (fileContent) {
					try {
						setLoading(true);
						BillingService.uploadBill(true, cloud, selectedFile)
							.then((res) => {
								setData(res.data);
								setLoading(false);
							})
							.catch(({ response }) => {
								setRejectedFiles(file);
								setLoading(false);
								notification.open(
									response?.detail || 'File is not Valid',
									NotificationSeverity.ERROR
								);
							});
					} catch (error) {
						setLoading(false);
						setRejectedFiles(file);
						notification.open(
							'Error parsing the file',
							NotificationSeverity.ERROR
						);
					}
				}
			};

			reader.readAsText(selectedFile);
		}
	};

	const {
		getRootProps,
		getInputProps,
		isFocused,
		isDragAccept,
		isDragReject,
		fileRejections,
	} = useDropzone({
		accept: acceptedFileTypes,
		onDrop: (acceptedFiles: File[]) => {
			setRejectedFiles([]);
			setShowBill(true);
			handleProcessBillFile(acceptedFiles);
		},
	});

	const InvalidFileUI = (fileRejections: any) => {
		return (
			<>
				<InvalidFileIcon data-testid="invalid-file-icon" />
				<p style={{ fontSize: '16px', color: '#000000' }}>Invalid Bill File</p>
				<span style={{ color: '#767575', fontSize: '12px' }}>
					{rejectedFiles?.length
						? 'Something went wrong while reading the file, please try again.'
						: ` ${supportedFileDescription}`}
				</span>
				<span>{fileRejections[0]?.name}</span>
			</>
		);
	};

	const style: DropzoneRootProps = useMemo(
		() => ({
			...baseStyle,
			...(isFocused && !fileRejections.length ? focusedStyle : {}),
			...(isDragAccept ? acceptStyle : {}),
			...(isDragReject || fileRejections.length || rejectedFiles?.length
				? rejectStyle
				: {}),
		}),
		[
			isFocused,
			fileRejections.length,
			isDragAccept,
			isDragReject,
			rejectedFiles?.length,
		]
	);

	// Handle file removal
	const handleRemoveFile = (ev: MouseEvent) => {
		ev.stopPropagation();
		setFile(null);
		setShowBill(false);
		setRejectedFiles([]);
	};

	return (
		<div
			className="upload-token-dropzone"
			data-cyid="cy-upload-token-dropzone"
			data-testid="cy-upload-token-dropzone"
		>
			<div {...getRootProps({ className: 'dropzone-root', style })}>
				<input {...getInputProps()} id="draggable-input" />
				{/* Render the file or the invalid file UI */}
				{fileRejections.length || rejectedFiles?.length ? (
					<InvalidFileUI rejectedFiles={fileRejections} />
				) : (
					<div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
						<div>
							<p>
								<span style={{ color: '#4285F4' }}>
									{file ? file[0]?.name : 'Upload Bill'}
								</span>
							</p>

							<span
								style={{
									color: '#767575',
									fontSize: '12px',
								}}
							>
								{supportedFileDescription}
							</span>
						</div>
						{/* Cross icon to remove the file */}
						{file && (
							<IconButton
								onClick={(ev: MouseEvent) => handleRemoveFile(ev)}
								style={{
									color: '#EA4335',
								}}
								size="small"
							>
								<CloseIcon />
							</IconButton>
						)}
					</div>
				)}
			</div>
		</div>
	);
};

export default UploadBill;
