import './ComputeProgressBars.scss';
import { classNames } from 'common/helpers';
import { IDBResource, INormalizedSaving, IVMResource } from 'common/interfaces';
import { ProgressBar } from 'components/ProgressBar/ProgressBar';

function ComputeProgressBars({
	resource,
	className,
}: {
	resource: IVMResource;
	className?: string;
	saving?: INormalizedSaving | null;
}) {
	return (
		<div
			data-cyid="cy-compute-progress-bars-component"
			className={classNames('compute-progress-bars-component', className)}
		>
			<ProgressBar
				max={100}
				value={null}
				color="#4285F4"
				title="CPU"
				percentageValue={
					parseFloat(
						(
							resource as IVMResource
						)?.normalizedObject?.cpu_utilization?.toFixed(2)
					) || 0
				}
			/>
			<ProgressBar
				max={100}
				value={null}
				color="#5ED0F8"
				title="MEM"
				percentageValue={
					parseFloat(
						(
							resource as IVMResource
						)?.normalizedObject?.memory_utilization?.toFixed(2)
					) || 0
				}
			/>
		</div>
	);
}

function DBProgressBars({
	resource,
	className,
}: {
	resource: IDBResource;
	className?: string;
	saving: INormalizedSaving | null;
}) {
	return (
		<div
			data-cyid="cy-compute-progress-bars-component"
			className={classNames('compute-progress-bars-component', className)}
		>
			<ProgressBar
				max={100}
				value={null}
				color="#4285F4"
				title="CPU"
				percentageValue={
					(resource as IDBResource).normalizedObject.cpu_utilization
				}
			/>
			<ProgressBar
				max={100}
				value={null}
				color="#4285F4"
				title="Memory"
				percentageValue={
					(resource as IDBResource).normalizedObject.memory_utilization
				}
			/>
		</div>
	);
}

export { ComputeProgressBars, DBProgressBars };
