import React, { useState } from 'react';
import {
	Drawer,
	TextField,
	MenuItem,
	Button,
	Box,
	FormControl,
	InputLabel,
	Select,
	styled,
	Skeleton,
} from '@mui/material';
import { IntegrationService } from 'api/services/integration.service';
import { useNotifications } from 'components/Notifications/useNotifications';
import { NotificationSeverity } from 'components/Notifications/Notification/Notification';
import { LoadingProgress } from 'components/LoadingProgress/LoadingProgress';
import CreatableSelect from 'react-select/creatable';
import { MultiValue } from 'react-select';
import './CreateTickets.scss';
import AIButton from './AIButton';
import {
	IDBResource,
	IGKEResource,
	IIPs,
	IRDSSnapshots,
	ISnapshots,
	IVMResource,
	IVolumeResource,
} from 'common/interfaces';
import { useSelector } from 'react-redux';
import { selectListOfIntegrations } from 'store/integrations/integration-selector';
interface CreateTicketDrawerProps {
	resourceType:
		| 'compute'
		| 'disk'
		| 'database'
		| 'disk_snapshots'
		| 'database'
		| 'database_snapshots'
		| 'ips'
		| 'cluster';
	open: boolean;
	onClose: () => void;
	integration_id: string;
	resource:
		| IVMResource
		| IDBResource
		| IVolumeResource
		| IGKEResource
		| IIPs
		| ISnapshots
		| IRDSSnapshots;
	action: string;
}

// Moved StyledDrawer outside of the component
const StyledDrawer = styled(Drawer)(() => ({
	'& .MuiDrawer-paper': {
		width: '450px',
		padding: '20px',
	},
}));

const CreateTicket: React.FC<CreateTicketDrawerProps> = ({
	open,
	onClose,
	resourceType,
	integration_id,
	resource,
	action,
}) => {
	const [summary, setSummary] = useState<string>('');
	const [description, setDescription] = useState<string>('');
	const [issueType, setIssueType] = useState<string>('Task');
	const [isLoading, setLoading] = useState<boolean>(false);
	const [ticketLabel, setTicketLabel] = useState<
		{ label: string; value: string }[]
	>([]);
	const loading = useSelector(selectListOfIntegrations).loading;

	const notification = useNotifications();

	const issueTypes = ['Task'];

	const availableLabels = [
		{
			label: 'UI',
			value: 'UI',
		},
		{ label: 'Backend', value: 'Backend' },
		{ label: 'Urgent', value: 'Urgent' },
		{ label: 'Low Priority', value: 'Low Priority' },
		{ label: 'High Priority', value: 'High Priority' },
	];

	const handleChange = (newValue: MultiValue<any>) => {
		const labels = newValue
			.map((label) => {
				return label.value.trim() !== '' && label;
			})
			.filter(Boolean);
		setTicketLabel(labels as any[]);
	};

	const handleSubmit = (ev) => {
		ev.preventDefault();
		setLoading(true);
		const labels = ticketLabel.map((label) => label.value);
		const payload = {
			integration_id: integration_id,
			description,
			summary,
			type: issueType,
			labels,
			resource_type: resourceType,
		};

		IntegrationService.create_ticket(payload)
			.then(() => {
				notification.open(
					'Tickt is successfully created, please visit board to see the ticket',
					NotificationSeverity.SUCCESS
				);
			})
			.catch((err) => {
				notification.open(
					err?.response?.data?.detail ||
						'Something went wrong while creating ticket, please try again. ',
					NotificationSeverity.ERROR
				);
			})
			.finally(() => {
				setLoading(false);
				onClose();
			});
	};

	return (
		<StyledDrawer anchor="right" open={open} onClose={onClose}>
			<form onSubmit={handleSubmit}>
				<Box
					sx={{
						width: 400,
						padding: 2,
						display: 'flex',
						flexDirection: 'column',
						gap: 2,
					}}
				>
					<Box
						flex={1}
						display="flex"
						justifyContent="space-between"
						alignItems={'center'}
					>
						<h1>Create Ticket</h1>
						<AIButton
							resource={resource}
							resourceType={resourceType}
							action={action}
							setSummary={setSummary}
							setDescription={setDescription}
							setTicketLabel={setTicketLabel}
							isDisabled={loading || isLoading}
						/>
					</Box>
					{loading ? (
						<>
							<Skeleton
								variant="rectangular"
								width={369}
								height={55}
								animation={'wave'}
							/>
							<Skeleton
								variant="rectangular"
								width={369}
								height={171}
								animation={'wave'}
							/>
							<Skeleton
								variant="rectangular"
								width={369}
								height={56}
								animation={'wave'}
							/>
							<Skeleton
								variant="rectangular"
								width={369}
								height={56}
								animation={'wave'}
							/>
						</>
					) : (
						<>
							<TextField
								label="Summary"
								variant="outlined"
								fullWidth
								value={summary}
								onChange={(e) => setSummary(e.target.value)}
								required
							/>
							<TextField
								label="Description"
								variant="outlined"
								fullWidth
								multiline
								rows={6}
								value={description}
								onChange={(e) => setDescription(e.target.value)}
							/>

							<FormControl required fullWidth>
								<InputLabel>Type</InputLabel>
								<Select
									value={issueType}
									onChange={(e) => setIssueType(e.target.value as string)}
									label="Type"
									required
									defaultValue="Task"
								>
									{issueTypes.map((type) => (
										<MenuItem key={type} value={type}>
											{type}
										</MenuItem>
									))}
								</Select>
							</FormControl>
							<CreatableSelect
								options={availableLabels}
								isMulti
								value={ticketLabel}
								onChange={handleChange}
								classNamePrefix="ticket-labels-select"
								placeholder="Select or create your own labels..."
							/>
						</>
					)}

					<Button
						variant="contained"
						color="primary"
						type="submit"
						disabled={loading}
						sx={{
							'&.Mui-disabled': {
								backgroundColor: '#1976d2',
								opacity: 0.7,
								cursor: 'not-allowed',
								color: '#FFFF',
							},
						}}
					>
						Create
					</Button>
				</Box>
			</form>
			{isLoading && (
				<LoadingProgress text="Please wait, ticket is being created" />
			)}
		</StyledDrawer>
	);
};

export default CreateTicket;
