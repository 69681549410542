import { createAsyncThunk } from '@reduxjs/toolkit';
import { IntegrationService } from 'api/services/integration.service';
import { ClusterConfig } from 'common/interfaces';

export const getIntegrations = createAsyncThunk(
    'app.getIntegrations',
    async (__, { dispatch, rejectWithValue }
    ) => {
        try {
            const { data } = await IntegrationService.listIntegrations();
            return data
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    }
);

export const getSummary = createAsyncThunk(
    'app.getSummary',
    async ({
        resource_name,
        resource_type,
        current_monthly_cost,
        recommended_monthly_cost,
        savings_amount,
        savings_percentage,
        action,
        project_id,
        date,
        cpu_utilization,
        memory_utilization,
        current_machine_type,
        recommended_machine_type,
        project_name,
        days_unattached_for,
        cluster_config
    }: {
        resource_name: string;
        resource_type: string;
        action: string;
        project_id: string;
        date?: string;
        current_monthly_cost?: number;
        recommended_monthly_cost?: number;
        savings_amount?: number;
        savings_percentage?: number;
        cpu_utilization?: string;
        memory_utilization?: string;
        current_machine_type?: string;
        recommended_machine_type?: string;
        project_name?: string;
        days_unattached_for?: number;
        cluster_config?: ClusterConfig;
    }, { dispatch, rejectWithValue }
    ) => {
        try {
            const { data } = await IntegrationService.get_summary({
                resource_name,
                resource_type,
                current_monthly_cost,
                recommended_monthly_cost,
                savings_amount,
                savings_percentage,
                action,
                project_id,
                date,
                cpu_utilization,
                memory_utilization,
                current_machine_type,
                recommended_machine_type,
                project_name,
                days_unattached_for,
                cluster_config
            });
            return data
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    }
); 