import { IDiscoveryOverview, IDiscoveryProject } from 'common/interfaces';
import './Billing.scss';
import { useOutletContext } from 'react-router-dom';
import { PageHeader } from 'components/PageHeader/PageHeader';
import { ApplyProjections } from 'components/ApplyProjections/ApplyProjections';
import useDiscoveryTooltipContent from 'hooks/useDiscoveryTooltipContent';
import {
	Bar,
	BarChart,
	CartesianGrid,
	Legend,
	Line,
	LineChart,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from 'recharts';
import { Tile } from 'components/Tile/Tile';
import { useMemo } from 'react';

const Billing = () => {
	const {
		account,
		discovery,
	}: { account: IDiscoveryProject; discovery: IDiscoveryOverview } =
		useOutletContext();

	/**
	 * Returns the tooltip content for the account overview.
	 * The tooltip provides an overview of the account within the organization,
	 * along with its cost breakdown and recommended actions to reduce cloud spending.
	 *
	 * @returns The tooltip content as a styled string.
	 */
	const { getTooltipContent } = useDiscoveryTooltipContent({
		account,
		discovery,
	});

	const CustomTooltip: React.FC<{ payload?: any }> = ({ payload }) => {
		if (!payload || payload.length === 0) return null;

		const { total_cost, service_name, sku_name, project_id, date, daily_cost } =
			payload[0].payload;

		return (
			<div className="main-tooltip" data-testid="tooltip">
				{date && (
					<span
						className="main-tooltip__resource"
						data-testid="tooltip-resource"
					>
						{date}
					</span>
				)}
				<span className="main-tooltip__resource" data-testid="tooltip-resource">
					{service_name || sku_name || project_id}
				</span>
				<span className="main-tooltip__value" data-testid="tooltip-value">
					$ {total_cost || daily_cost || 0}
				</span>
			</div>
		);
	};
	const CustomServicesTooltip: React.FC<{ payload?: any }> = ({ payload }) => {
		if (!payload || payload.length === 0) return null;

		const data_object = payload[0].payload;

		// Function to check if a key is a valid date (you can improve this regex to handle different date formats)
		const isDate = (key: string) => {
			// Example check: date format "dd-mm-yy" or "yyyy-mm-dd"
			const datePattern = /^\d{4}-\d{2}-\d{2}$/; // "yyyy-mm-dd" pattern
			return datePattern.test(key);
		};
		return (
			<div
				className="main-tooltip"
				data-testid="tooltip"
				style={{ maxWidth: '400px' }}
			>
				{/* Date Section */}
				<div className="tooltip-date">
					{Object.entries(data_object).map(([key, value]) =>
						isDate(value as string) ? (
							<span key={key} className="tooltip-date-value">
								{value as string}
							</span>
						) : null
					)}
				</div>

				{/* Tooltip Content */}
				<div className="tooltip-content">
					{Object.entries(data_object).map(([key, value]) =>
						!isDate(value as string) ? (
							<div key={key} className="tooltip-item">
								<span
									className="main-tooltip__resource"
									data-testid="tooltip-resource"
								>
									{key}:{' '}
								</span>
								<span
									className="main-tooltip__value"
									data-testid="tooltip-value"
								>
									{`$ ${value as string}`}
								</span>
							</div>
						) : null
					)}
				</div>
			</div>
		);
	};

	const projectDailyCost = useMemo(() => {
		if (!account?.billing?.projects_daily_costs) return [];
		return account?.billing?.projects_daily_costs.map((item) => {
			const date = Object.keys(item)[0];
			return {
				date,
				daily_cost: item[date][0].daily_cost,
				project_id: item[date][0].project_id,
			};
		});
	}, [account?.billing?.projects_daily_costs]);

	const serviceDailyCost = useMemo(() => {
		if (!account?.billing?.service_daily_costs) return [];
		const data = account?.billing?.service_daily_costs;
		return data.map((entry) => {
			const date = Object.keys(entry)[0];
			const services = entry[date];

			const formattedEntry: any = { date };

			services.forEach((service) => {
				formattedEntry[service.service_name] = service.daily_cost;
			});

			return formattedEntry;
		});
	}, [account?.billing?.service_daily_costs]);

	const SKUDailyCost = useMemo(() => {
		if (!account?.billing?.sku_daily_costs) return [];
		const data = account?.billing?.sku_daily_costs;
		let transformed: any[] = [];

		data.forEach((entry) => {
			Object.entries(entry).forEach(([date, skus]) => {
				let formattedEntry: any = { date };

				(skus as any[]).forEach((sku: any) => {
					formattedEntry[sku.sku_name] = sku.daily_cost; // Map SKU cost against date
				});

				transformed.push(formattedEntry);
			});
		});

		return transformed;
	}, [account?.billing?.sku_daily_costs]);

	const colors = [
		'#f27d7d',
		'#4f73d3',
		'#FFA600',
		'#EF5675',
		'#7A5195',
		'#1EB564',
		'#003f94',
		'#4285F4',
		'#34A853',
		'#FBBC05',
		'#f4c978',
		'#ffebb0',
		'#A0EBC7',
		'#ffc658',
	];
	return (
		account && (
			<div
				className="billing-overview-component"
				data-cyid="cy-billing-component"
				data-testid="billing component"
			>
				<PageHeader
					title={account?.name || ''}
					label="Account"
					tooltipContent={getTooltipContent()}
					lastSync={account?.last_synced_at}
				/>
				<ApplyProjections
					savingPercentage={account?.savings_percentage}
					potentialCost={account?.potential_cost}
					actualCost={account?.actual_cost}
					className="account-overview-component-apply-projections"
					showSavingSelection={false}
					isAccountsOverview={false}
					account={account}
					discovery={discovery}
					data_cyid="cy-account-overview-projections"
				/>
				<div
					className="billing-overview-component__main"
					data-testid="billing-overview-main"
				>
					<div
						className="billing-overview-component__main__chart"
						data-testid="project-cost-chart"
					>
						<div
							className="billing-overview-component__main__chart--header"
							data-testid="project-cost-chart-header"
						>
							<h3>Project Cost</h3>
							<Tile text={account?.name} color="green" />
						</div>
						<div
							className="billing-overview-component__main__chart--area"
							data-testid="project-cost-chart-area"
						>
							<ResponsiveContainer width="100%" height={100}>
								<BarChart
									layout="vertical"
									data={account?.billing?.project_costs || []}
									margin={{ top: 20, right: 20, left: 20, bottom: 5 }}
									barSize={30}
									barCategoryGap={20}
								>
									<XAxis type="number" hide />
									<YAxis type="category" dataKey="project_name" hide />
									<Tooltip
										content={CustomTooltip}
										cursor={{ fill: 'transparent' }}
									/>
									<Legend
										layout="vertical"
										align="left"
										verticalAlign="middle"
										wrapperStyle={{ top: '48px' }}
										iconType="wye"
										formatter={() =>
											`$ ${account?.billing?.project_costs[0]?.total_cost}`
										}
									/>
									<Bar dataKey="total_cost" fill="#f4c978" />
								</BarChart>
							</ResponsiveContainer>
						</div>
					</div>
					<div
						className="billing-overview-component__main__chart"
						data-testid="service-cost-chart"
					>
						<div
							className="billing-overview-component__main__chart--header"
							data-testid="project-cost-chart-header"
						>
							<h3>Project Dailt Cost</h3>
							<Tile text={account?.name} color="green" />
						</div>
						<div
							className="billing-overview-component__main__chart--area"
							data-testid="service-cost-chart-area"
						>
							<ResponsiveContainer width="100%" height={300}>
								<LineChart data={projectDailyCost}>
									<XAxis
										dataKey="date"
										tick={{ fontSize: 12 }}
										angle={-45}
										textAnchor="end"
									/>
									<YAxis />
									<Tooltip content={CustomTooltip} />
									<Line
										type="monotone"
										dataKey="daily_cost"
										stroke="#4285F4"
										strokeWidth={2}
									/>
								</LineChart>
							</ResponsiveContainer>
						</div>
					</div>
					<div
						className="billing-overview-component__main__chart"
						data-testid="service-cost-chart"
					>
						<div
							className="billing-overview-component__main__chart--header"
							data-testid="service-cost-chart-header"
						>
							<h3>Service Cost</h3>
						</div>
						<div
							className="billing-overview-component__main__chart--area"
							data-testid="service-cost-chart-area"
						>
							<ResponsiveContainer width="100%" height={400}>
								<BarChart
									data={account?.billing?.service_costs}
									barSize={40}
									margin={{
										top: 5,
										right: 30,
										left: 20,
										bottom: 25,
									}}
									barGap={20}
									barCategoryGap={20}
								>
									<CartesianGrid strokeDasharray="3 3" />
									<XAxis
										dataKey="service_name"
										tick={{ fontSize: 12 }}
										angle={-45}
										textAnchor="end"
										hide
									/>
									<YAxis />
									<Tooltip content={CustomTooltip} />
									<Legend
										layout="vertical"
										align="center"
										verticalAlign="bottom"
										iconType="square"
										wrapperStyle={{ paddingTop: '20px' }}
										formatter={(value) => {
											if (value === 'total_cost')
												return 'Total Cost of each service used within the project';
											return value;
										}}
									/>
									<Bar dataKey="total_cost" fill="#4f73d3" />
								</BarChart>
							</ResponsiveContainer>
						</div>
					</div>
					<div
						className="billing-overview-component__main__chart"
						data-testid="service-cost-chart"
					>
						<div
							className="billing-overview-component__main__chart--header"
							data-testid="service-cost-chart-header"
						>
							<h3>Services Daily Cost</h3>
						</div>
						<div
							className="billing-overview-component__main__chart--area"
							data-testid="service-cost-chart-area"
						>
							<ResponsiveContainer width="100%" height={400}>
								<BarChart
									data={serviceDailyCost}
									margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
								>
									<XAxis dataKey="date" />
									<YAxis />
									<Tooltip
										content={CustomServicesTooltip}
										labelFormatter={(label) => label}
										active={true}
									/>
									<Legend />
									{Object.keys(serviceDailyCost[0])
										.filter((key) => key !== 'date')
										.map((key, index) => (
											<Bar
												key={key}
												dataKey={key}
												stackId="a"
												fill={colors[index]}
												barSize={20}
											/>
										))}
								</BarChart>
							</ResponsiveContainer>
						</div>
					</div>
					<div
						className="billing-overview-component__main__chart"
						data-testid="sku-cost-chart"
					>
						<div
							className="billing-overview-component__main__chart--header"
							data-testid="sku-cost-chart-header"
						>
							<h3>SKU Cost</h3>
						</div>
						<div
							className="billing-overview-component__main__chart--area"
							data-testid="sku-cost-chart-area"
						>
							<ResponsiveContainer width="100%" height={400}>
								<LineChart
									data={account?.billing?.sku_costs}
									margin={{ top: 20, right: 30, left: 20, bottom: 30 }}
								>
									<CartesianGrid strokeDasharray="3 3" />
									<XAxis
										dataKey="sku_name"
										tick={{ fontSize: 10 }}
										angle={-45}
										textAnchor="end"
										hide
									/>
									<YAxis />
									<Tooltip content={CustomTooltip} />
									<Legend
										layout="vertical"
										align="center"
										verticalAlign="bottom"
										iconType="square"
										wrapperStyle={{ paddingTop: '20px' }}
										formatter={(value) => {
											if (value === 'total_cost')
												return 'Total SKU Cost, highlighting specific resource expenditures ';
											return value;
										}}
									/>
									<Line
										type="monotone"
										dataKey="total_cost"
										stroke="#7A5195"
										activeDot={{ r: 8 }}
									/>
								</LineChart>
							</ResponsiveContainer>
						</div>
					</div>
				</div>
			</div>
		)
	);
};

export default Billing;
