import { createAsyncThunk } from '@reduxjs/toolkit';
import { ConnectorsService } from 'api/services/connectors.service';
import { updateConnectorIds } from 'common/helpers';
import { ConnectorType } from 'common/interfaces';

export const createAWSConnector = createAsyncThunk(
    'app.createAWSConnector',
    async (
        { name, access_key_id, secret_access_key, region, file, project_id, expires_in, session_token,
            role_arn }:
            {
                name: string,
                access_key_id?: string,
                secret_access_key?: string,
                region?: string,
                file?: File,
                project_id?: string,
                expires_in?: number | null,
                session_token?: string,
                role_arn?: string
            },
        { dispatch, rejectWithValue }
    ) => {
        try {
            const { data } = await ConnectorsService.createAWS(
                name,
                access_key_id,
                secret_access_key,
                region,
                file,
                project_id,
                expires_in,
                session_token || undefined,
                role_arn || undefined
            );

            updateConnectorIds('aws', data.connector[0]);

            return { data: data.data, token_scopes: data.token_scope, categorical_perms: data.categorical_perms, connectors: data.connector };
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    }
);

export const createAzureConnector = createAsyncThunk(
    'app.createAzureConnector',
    async (
        { name, azure_tenant_id, subscription_id, client_id,
            client_secret, project_id, expires_in }:
            {
                name: string,
                azure_tenant_id: string,
                subscription_id: string,
                client_id: string,
                client_secret: string,
                project_id?: string,
                expires_in?: number | null
            },
        { dispatch, rejectWithValue }
    ) => {
        try {
            const { data } = await ConnectorsService.createAzure(
                name,
                subscription_id,
                azure_tenant_id,
                client_id,
                client_secret,
                project_id,
                expires_in
            );
            updateConnectorIds('azure', data.connector[0]);

            return { data: data.data, token_scopes: data.token_scope, categorical_perms: data.categorical_perms, connectors: data.connector };
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    }
);

export const createGCPConnector = createAsyncThunk(
    'app.createGCPConnector',
    async (
        { name, type, file, token, credentials, project_id, expires_in }:
            {
                name: string,
                type: ConnectorType,
                file?: File,
                token?: string | null,
                credentials?: string | null,
                project_id?: string,
                expires_in?: number | null
            },
        { dispatch, rejectWithValue }
    ) => {
        try {
            const { data } = await ConnectorsService.create(
                name,
                type,
                file,
                token,
                credentials,
                project_id,
                expires_in
            );
            updateConnectorIds('gcp', data.connectors[0]);

            return { data: data.data, token_scopes: data.token_scope, categorical_perms: data.categorical_perms, connectors: data.connectors };
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    }
);

export const getTokenConfig = createAsyncThunk(
    'app.getTokenConfig',
    async (
        { token, project_id }:
            {
                token: string | null,
                project_id: string,
            },
        { dispatch, rejectWithValue }
    ) => {
        try {
            const { data } = await ConnectorsService.getTokenConfigs(token, project_id);


            return { data: data.data, token_scopes: data.token_scope, categorical_perms: data.categorical_perms, connectors: [] };
        } catch (err: any) {
            return rejectWithValue(err);
        }
    }
);