import React from 'react';
import { Box, Card, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from 'store/app/app-selectors';
import { formatDate } from 'common/helpers';
import BillingHistory from './BillingHistory';

const PricingCards: React.FC = () => {
	const user = useSelector(selectCurrentUser);

	return (
		<Box display="flex" gap={3} flexWrap="wrap">
			<Card variant="outlined" sx={{ width: '100%' }}>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						gap: '10px',
						padding: '12px',
						width: 'fit-content',
					}}
				>
					<Box sx={{ display: 'flex', alignItems: 'center', gap: 20 }}>
						<Typography variant="h5">Current Plan</Typography>
						<Typography variant="subtitle1">$500/mo</Typography>
					</Box>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
						}}
					>
						<Typography variant="subtitle1">Started at</Typography>
						<Typography variant="subtitle1">
							{formatDate(user?.started_at)}
						</Typography>
					</Box>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
						}}
					>
						<Typography variant="subtitle1">Renews On</Typography>
						<Typography variant="subtitle1">
							{formatDate(user?.renewed_on)}
						</Typography>
					</Box>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
						}}
					>
						<Typography variant="subtitle1">Expires On</Typography>
						<Typography variant="subtitle1">
							{formatDate(user?.expires_at)}
						</Typography>
					</Box>
				</Box>
			</Card>
			<BillingHistory />
		</Box>
	);
};

export default PricingCards;
