import { classNames } from 'common/helpers';
import './Tile.scss';

interface TileProps {
	text: string;
	color:
		| 'blue'
		| 'yellow'
		| 'green'
		| 'dark-green'
		| 'red'
		| 'light-blue'
		| 'warning';
	className?: string;
}
function Tile({ text, color, className, ...rest }: TileProps) {
	return (
		<span
			data-cyid="cy-tile-component"
			className={classNames('tile-component', color, className)}
			{...rest}
		>
			{text}
		</span>
	);
}

export { Tile };
