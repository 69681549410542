import {
	Grid,
	Typography,
	Skeleton,
	Box,
	Card,
	CardContent,
} from '@mui/material';
import React from 'react';
import {
	PieChart,
	Pie,
	Cell,
	Tooltip,
	Legend,
	ResponsiveContainer,
	BarChart,
	Bar,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip as ReTooltip,
	Legend as ReLegend,
} from 'recharts';

const BillAnalysis = ({
	data,
	isLoading,
}: {
	data: any;
	isLoading: boolean;
}) => {
	// Safe access to the data fields
	const highCostServices = data?.high_cost_services || [];
	const savingsOpportunities = data?.savings_opportunities?.details || [];
	const totalSpending = data?.total_spending?.amount || 0;
	const totalSavings = data?.estimated_total_savings?.amount || 0;
	const trendsAndInsights = data?.trends_and_optimization_insights || {};
	const recommendationsForCostReduction =
		data?.recommendations_for_cost_reduction || [];

	// Render Skeleton Loader
	const renderSkeletonLoader = () => (
		<Grid container spacing={2}>
			<Grid item xs={12} md={6}>
				<Skeleton variant="text" width="100%" height={50} />
			</Grid>
			<Grid item xs={12} md={6}>
				<Skeleton variant="text" width="100%" height={50} />
			</Grid>
			<Grid item xs={12}>
				<Skeleton variant="rectangular" width="100%" height={300} />
			</Grid>
		</Grid>
	);

	// Render high cost services
	const renderHighCostServices = () => {
		if (highCostServices.length === 0) return null;

		return highCostServices.map((service: any) => (
			<Card variant="outlined" key={service.service} sx={{ marginBottom: 2 }}>
				<CardContent>
					<Typography variant="h6">{service.service}</Typography>
					<Typography variant="body2">${service.cost}</Typography>
				</CardContent>
			</Card>
		));
	};

	// Render savings opportunities
	const renderSavingsOpportunities = () => {
		if (savingsOpportunities.length === 0) return null;

		return savingsOpportunities.map((item: any) => (
			<Card variant="outlined" key={item.area} sx={{ marginBottom: 2 }}>
				<CardContent>
					<Typography variant="h6">{item.area}</Typography>
					<Typography variant="body2">
						<strong>Potential Savings:</strong>{' '}
						<span style={{ fontWeight: 'bold', color: '#4285F4' }}>
							${item.potential_saving}
						</span>
					</Typography>
					<Typography variant="body2">{item.recommendation}</Typography>
				</CardContent>
			</Card>
		));
	};

	// Render trends and optimization insights
	const renderTrendsAndInsights = () => {
		if (!trendsAndInsights.title || !trendsAndInsights.description) return null;

		return (
			<Grid item xs={6}>
				<Card variant="outlined" sx={{ marginBottom: 2 }}>
					<CardContent>
						<Typography variant="h6" fontWeight={700}>
							{trendsAndInsights.title}
						</Typography>
						<Typography variant="body2">
							{trendsAndInsights.description}
						</Typography>
					</CardContent>
				</Card>
			</Grid>
		);
	};

	// Render recommendations for cost reduction
	const renderRecommendationsForCostReduction = () => {
		if (recommendationsForCostReduction.length === 0) return null;

		return (
			<Grid item md={6}>
				<Typography variant="h6" fontWeight={700} sx={{ marginBottom: 2 }}>
					Saving Recommendations for Cost Reduction
				</Typography>
				{recommendationsForCostReduction.map((rec: any) => (
					<Card variant="outlined" key={rec.title} sx={{ marginBottom: 2 }}>
						<CardContent>
							<Typography variant="h6">{rec.title}</Typography>
							<Typography variant="body2">{rec.details}</Typography>
						</CardContent>
					</Card>
				))}
			</Grid>
		);
	};

	// Render charts
	const renderCharts = () => {
		const pieData = [
			{ name: 'Total Spending', value: totalSpending },
			{ name: 'Estimated Savings', value: totalSavings },
		];

		return (
			<Grid container spacing={2}>
				<Grid item xs={12} md={6}>
					<Typography variant="h6" gutterBottom fontWeight={700}>
						Spending vs Savings
					</Typography>
					<ResponsiveContainer width="100%" height={300}>
						<PieChart>
							<Pie
								data={pieData}
								dataKey="value"
								nameKey="name"
								outerRadius={80}
								fill="#8884d8"
							>
								{pieData.map((entry, index) => (
									<Cell
										key={`cell-${index}`}
										fill={index === 0 ? '#4285F4' : '#387908'}
									/>
								))}
							</Pie>
							<Tooltip />
							<Legend />
						</PieChart>
					</ResponsiveContainer>
				</Grid>
				<Grid item xs={12} md={6}>
					<Typography variant="h6" gutterBottom fontWeight={700}>
						High Cost Services Breakdown
					</Typography>
					<ResponsiveContainer width="100%" height={300}>
						<BarChart data={highCostServices}>
							<CartesianGrid strokeDasharray="3 3" />
							<XAxis dataKey="service" />
							<YAxis />
							<ReTooltip />
							<ReLegend />
							<Bar maxBarSize={30} dataKey="cost" fill="#82ca9d" />
						</BarChart>
					</ResponsiveContainer>
				</Grid>
			</Grid>
		);
	};

	// Render main content, checking for data availability
	const renderContent = () => (
		<Grid container spacing={3}>
			{/* Render Total Spending and Estimated Savings if available */}
			{totalSpending || totalSavings ? (
				<Grid item xs={12} md={6}>
					{totalSpending > 0 && (
						<Typography
							variant="h6"
							sx={{ fontWeight: 'bold', fontSize: '1.25rem', color: '#4285F4' }}
						>
							Total Spending: ${totalSpending} {data?.total_spending?.currency}
						</Typography>
					)}
					{totalSavings > 0 && (
						<Typography
							variant="h6"
							sx={{ fontWeight: 'bold', fontSize: '1.25rem', color: '#387908' }}
						>
							Estimated Savings: ${totalSavings}{' '}
							{data?.estimated_total_savings?.currency}
						</Typography>
					)}
				</Grid>
			) : null}

			<Grid item xs={12}>
				{renderCharts()}
			</Grid>

			{/* Render Savings Opportunities Section */}
			{savingsOpportunities.length > 0 && (
				<Grid item xs={12} md={6}>
					<Typography variant="h6" fontWeight={700}>
						Savings Opportunities
					</Typography>
					{renderSavingsOpportunities()}
				</Grid>
			)}

			{/* Render High Cost Services Section */}
			{highCostServices.length > 0 && (
				<Grid item xs={12} md={6}>
					<Typography variant="h6" fontWeight={700}>
						High Cost Services
					</Typography>
					{renderHighCostServices()}
				</Grid>
			)}
			{/* Render Recommendations for Cost Reduction Section */}
			{renderRecommendationsForCostReduction()}
			{/* Render Trends and Insights Section */}
			{renderTrendsAndInsights()}
		</Grid>
	);

	return (
		<Card variant="outlined" sx={{ padding: 2 }}>
			{isLoading ? renderSkeletonLoader() : renderContent()}
		</Card>
	);
};

export default BillAnalysis;
