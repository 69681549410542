import { client } from 'api/client';

export class BillingService {
    static readonly path = 'api/billing';

    static uploadBill = (ai_summary_only: boolean, cloud_name: string, file: File) => {
        const formData = new FormData();
        formData.append('file', file);

        return client.post(`${BillingService.path}/upload`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            params: {
                ai_summary_only,
                cloud_name
            }
        });
    }
}
