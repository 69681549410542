import { client } from 'api/client';
import { ClusterConfig } from 'common/interfaces';

export class IntegrationService {
    private static readonly path = 'api/v1/ticket';

    static create(
        {
            type,
            project_key,
            email,
            api_token,
            url,
            default_labels,
            is_active
        }: {
            type: string,
            project_key: string,
            email: string,
            api_token: string,
            url: string,
            default_labels: string,
            is_active: boolean
        }
    ) {
        return client.post(`${IntegrationService.path}/create-integration`, {
            type,
            project_key,
            email,
            api_token,
            url,
            default_labels,
            is_active: is_active || true

        });
    }
    static update(
        {
            integration_id,
            project_key,
            email,
            api_token,
            url,
            default_labels,
            is_active,
            channel
        }: {
            integration_id: string,
            project_key: string,
            email: string,
            api_token: string,
            url: string,
            default_labels: string,
            is_active: boolean,
            channel?: string | null
        }
    ) {
        return client.patch(`${IntegrationService.path}/update-integration/${integration_id}`, {
            project_key,
            email,
            api_token,
            url,
            default_labels,
            is_active,
            channel
        });
    }


    static deleteIntegration(integration_id: string) {
        return client.delete(`${IntegrationService.path}/delete-integration/${integration_id}`);
    }

    static listIntegrations() {
        return client.get(`${IntegrationService.path}/list-integrations`);
    }

    static getIntegrationById(integration_id: string) {
        return client.get(`${IntegrationService.path}/integration/${integration_id}`);
    }

    //---------------------- tickets -------------------------------------------------
    //--------------------------------------------------------------------------------

    static create_ticket(
        {
            integration_id,
            description,
            summary,
            type,
            labels,
            resource_type,
        }: {
            integration_id: string,
            description: string,
            summary: string,
            type: string,
            labels: string[],
            resource_type: string,
        }
    ) {
        return client.post(`${IntegrationService.path}/create-ticket`, {
            integration_id,
            description,
            summary,
            type,
            labels,
            resource_type,
        });
    }

    static get_summary(
        {
            resource_name,
            resource_type,
            current_monthly_cost,
            recommended_monthly_cost,
            savings_amount,
            savings_percentage,
            action,
            project_id,
            date,
            cpu_utilization,
            memory_utilization,
            current_machine_type,
            recommended_machine_type,
            project_name,
            days_unattached_for,
            cluster_config
        }: {
            resource_name: string;
            resource_type: string;
            action: string;
            project_id: string;
            date?: string;
            current_monthly_cost?: number;
            recommended_monthly_cost?: number;
            savings_amount?: number;
            savings_percentage?: number;
            cpu_utilization?: string;
            memory_utilization?: string;
            current_machine_type?: string;
            recommended_machine_type?: string;
            project_name?: string;
            days_unattached_for?: number;
            cluster_config?: ClusterConfig;
        }
    ) {
        return client.post(`${IntegrationService.path}/generate-ticket-description`, {
            resource_name,
            resource_type,
            current_monthly_cost,
            recommended_monthly_cost,
            savings_amount,
            savings_percentage,
            action,
            project_id,
            date,
            cpu_utilization,
            memory_utilization,
            current_machine_type,
            recommended_machine_type,
            project_name,
            days_unattached_for,
            cluster_config
        });
    }
}


