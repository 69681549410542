import { Card, Typography } from '@mui/material';
import { PaymentService } from 'api/services/payment.service';
import { Table } from 'components/Table/Table';
import React, { useCallback, useEffect, useState } from 'react';

const BillingHistory = () => {
	const [history, setHistory] = useState([]);
	const [isLoading, setLoading] = useState(false);

	const getPaymentHistory = useCallback(() => {
		setLoading(true);
		PaymentService.paymentHistory()
			.then((res) => {
				setHistory(res.data);
				setLoading(false);
			})
			.catch((error) => {
				setLoading(false);
			});
	}, []);

	useEffect(() => {
		getPaymentHistory();
	}, [getPaymentHistory]);

	const columns = [
		{
			name: 'Date',
			element: (billing: any) => (
				<span className="resource-table-cell-name">{billing?.date}</span>
			),
			maxWidth: '150px',
			sortable: true,
		},

		{
			name: 'Amount',
			element: (billing: any) => (
				<span className="accounts-table-cell-actual-cost">
					USD {billing?.amount}
				</span>
			),
			sortable: true,
		},

		{
			name: 'Next Renewal Date',
			element: (billing: any) => (
				<span className="accounts-table-cell-actual-cost">
					{billing?.next_renewal}
				</span>
			),
			sortable: true,
		},
		{
			name: 'Subscription Cycle',
			element: (billing: any) => (
				<span className="accounts-table-cell-actual-cost  ">
					{billing?.subscription_cycle}
				</span>
			),
			sortable: true,
			className: 'text-center',
		},
	];

	return (
		<Card
			variant="outlined"
			sx={{
				width: '100%',
				padding: '20px',
				height: '100%',
				display: 'flex',
				flexDirection: 'column',
				gap: '15px',
			}}
		>
			<Typography variant="h5">Billing History</Typography>
			<Table
				totalCount={history.length || 0}
				itemsPerPage={20}
				rows={history || []}
				columns={columns}
				className="account-resources-list"
				data_cyid="cy-table-clients-data"
				isSkeleton={isLoading}
			/>
		</Card>
	);
};

export default BillingHistory;
