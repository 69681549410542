import { Popup } from 'components/Popup/Popup';
import SubscriptionPlan from './SubscriptionPlan';
import './SubscriptionPopup.scss';

interface ISubscriptionPopup {
	open: boolean;
	onClose: () => void;
}

/**
 * Subscription Popup component.
 *
 * @param {boolean} open - The state of the popup.
 * @param {() => void} onClose - The function to close the popup.
 * @returns {JSX.Element} A JSX Element.
 */
const SubscriptionPopup = ({ open, onClose }: ISubscriptionPopup) => {
	return (
		<Popup
			data_cyid="cy-subscription-plan-connection"
			open={open}
			onClose={onClose}
			withButtons={false}
			className="subscription-plan-popup"
			showCloseButton={false}
		>
			<SubscriptionPlan onClose={onClose} showTitles />
		</Popup>
	);
};

export default SubscriptionPopup;
