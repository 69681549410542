import { ChangeEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Provider } from 'common/interfaces';
import { Button } from 'components/Button/Button';
import { TextInput } from 'components/TextInput/TextInput';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { createAzureConnector } from 'store/connectors/connectors-thunks';
import { useSelector } from 'react-redux';
import { selectCurrentCloud } from 'store/clouds/clouds-selectors';
import { toast } from 'react-toastify';
import './CredentialBaseConnections.scss';

const AzureCredentials = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const cloud = useSelector(selectCurrentCloud);

	const [azureInput, setAzureInput] = useState<{
		clientID: string;
		clientSecret: string;
		tenantID: string;
		subscriptionID: string;
	}>({ clientID: '', clientSecret: '', tenantID: '', subscriptionID: '' });

	return (
		<div className="aws-connect-box__aws-input">
			<TextInput
				data_cyid="aws-access-key-id"
				id="aws-access-key-id"
				value={azureInput.clientID}
				onChange={(e: ChangeEvent<HTMLInputElement>) =>
					setAzureInput({ ...azureInput, clientID: e.target.value })
				}
				placeholder="Client ID"
				className="aws-access-input"
			/>
			<TextInput
				data_cyid="aws-access-key-secret"
				id="aws-access-key-secret"
				value={azureInput.clientSecret}
				onChange={(e: ChangeEvent<HTMLInputElement>) =>
					setAzureInput({
						...azureInput,
						clientSecret: e.target.value,
					})
				}
				placeholder="Client Secret"
				className="aws-access-input"
			/>
			<TextInput
				data_cyid="aws-access-key-secret"
				id="aws-access-key-secret"
				value={azureInput.tenantID}
				onChange={(e: ChangeEvent<HTMLInputElement>) =>
					setAzureInput({ ...azureInput, tenantID: e.target.value })
				}
				placeholder="Tenant ID"
				className="aws-access-input"
			/>
			<TextInput
				data_cyid="aws-access-key-secret"
				id="aws-access-key-secret"
				value={azureInput.subscriptionID}
				onChange={(e: ChangeEvent<HTMLInputElement>) =>
					setAzureInput({
						...azureInput,
						subscriptionID: e.target.value,
					})
				}
				placeholder="Subscription ID"
				className="aws-access-input"
			/>
			<Button
				text={`Connect ${cloud?.name}`}
				className="aws-connect-box__aws-connect-button blue small"
				data_cyid="cy-aws-connect"
				data-testid="aws-connection-button"
				onClick={() => {
					dispatch(
						createAzureConnector({
							name: Provider.AZURE,
							client_id: azureInput.clientID,
							client_secret: azureInput.clientSecret,
							subscription_id: azureInput.subscriptionID,
							azure_tenant_id: azureInput.tenantID,
						})
					)
						.unwrap()
						.then(() => {
							navigate('/selectCloud/uploadToken', {
								state: { step: 1 },
							});
						})
						.catch((err) => {
							toast.error(err?.detail || 'Invalid Credentials');
						});
				}}
				disabled={
					!azureInput.clientID ||
					!azureInput.clientSecret ||
					!azureInput.tenantID ||
					!azureInput.subscriptionID
				}
			/>
		</div>
	);
};

export default AzureCredentials;
