import { ChangeEvent, MouseEvent, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	Button,
	Drawer,
	FormControl,
	FormControlLabel,
	FormLabel,
	Paper,
	Radio,
	RadioGroup,
	Skeleton,
	Step,
	StepLabel,
	Stepper,
	Typography,
} from '@mui/material';
import {
	AutoAwesomeOutlined,
	Cloud,
	CloudDoneOutlined,
} from '@mui/icons-material';
import { ReactComponent as AWS } from 'assets/svgs/aws-logo.svg';
import { ReactComponent as Azure } from 'assets/svgs/azure.svg';
import { ReactComponent as GCP } from 'assets/svgs/googleCloud.svg';
import { CloudConnectUI } from 'features/Clouds/ConnectCloud/ConnectCloudUI';
import { useSelector } from 'react-redux';
import { selectCloudsData } from 'store/clouds/clouds-selectors';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { setCurrentCloud } from 'store/clouds/clouds-thunks';
import { IBillAnalysisData, ICloud } from 'common/interfaces';
import { CloudConnectLoader } from 'features/Discoveries/DiscoveriesPlaceholder/ConnectCloudLoader';
import { selectConnector } from 'store/connectors/connectors-selectors';
import './SelectCloud.scss';
import UploadBill from 'features/Discoveries/UploadBill/UploadBill';
import BillAnalysis from 'features/Discoveries/UploadBill/BillAnalysis';
import { Tile } from 'components/Tile/Tile';

export interface Cloud {
	name: string;
	icon: React.ReactNode;
	id: string;
	connected?: boolean;
}

const steps = ['Select Cloud Provider', 'Connect Cloud Provider'];

const SelectCloud = () => {
	const [activeStep, setActiveStep] = useState(0);
	const [selectedCloud, setSelectedCloud] = useState<ICloud | null>(null);
	const { data: connectedClouds, loading: cloudLoading } =
		useSelector(selectCloudsData);
	const { loading } = useSelector(selectConnector);
	const [billLoading, setBillLoading] = useState(false);
	const [isOpen, setOpen] = useState(false);
	const [billData, setBillData] = useState<IBillAnalysisData | null>(null);
	const [showBillData, setShowBillData] = useState<boolean>(false);
	const [selectedService, setSelectedService] = useState('');

	const handleChange = (event) => {
		setSelectedService(event.target.value);
	};
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const clouds: ICloud[] = [
		{
			name: 'Amazon Web Services',
			icon: <AWS />,
			id: 'aws',
			created_at: new Date().toISOString(),
			projects: 0,
		},
		{
			name: 'Microsoft Azure',
			icon: <Azure height={20} width={20} />,
			id: 'azure',
			created_at: new Date().toISOString(),
			projects: 0,
		},
		{
			name: 'Google Cloud Platform',
			icon: <GCP />,
			id: 'gcp',
			created_at: new Date().toISOString(),
			projects: 0,
		},
	];

	const connectedCloudIds = connectedClouds?.map((cloud) => cloud?.id) || [];
	const availableClouds = clouds
		.map((cloud) => ({
			...cloud,
			connected: connectedCloudIds.includes(cloud.id),
		}))
		.sort((a, b) => Number(a.connected) - Number(b.connected));

	const handleStep = (step: number) => {
		setActiveStep(step);
	};

	const handleCloudSelect = async (cloud: any) => {
		const selectedCloud = {
			id: cloud.id,
			name: cloud.name,
			created_at: cloud.created_at,
			projects: cloud.projects,
		};
		try {
			await dispatch(setCurrentCloud(selectedCloud)).unwrap();
			setSelectedCloud(cloud);
			if (cloud.connected) {
				navigate('/discoveries');
			} else {
				setActiveStep(1);
			}
		} catch (error) {
			console.error('Failed to set current cloud:', error);
		}
	};

	return loading ? (
		<CloudConnectLoader />
	) : (
		<div className="select-cloud" data-testid="select-cloud">
			<Stepper
				activeStep={activeStep}
				alternativeLabel
				className="select-cloud__stepper"
			>
				{steps.map((label, index) => (
					<Step key={label}>
						<StepLabel onClick={() => index === 0 && handleStep(index)}>
							{label}
						</StepLabel>
					</Step>
				))}
			</Stepper>

			{/* Content for Step 0 */}
			{activeStep === 0 && (
				<div className="select-cloud-provider-step">
					<h1>Select Cloud Provider</h1>
					<p>Add a new cloud provider to your EazyOps account.</p>
					<div>
						<Button
							variant="outlined"
							sx={{
								display: 'flex',
								alignItems: 'center',
								gap: 2,
							}}
							onClick={() => setOpen(true)}
						>
							<AutoAwesomeOutlined />
							Quick Saving Analysis
						</Button>
					</div>

					<ul className="select-cloud-list">
						{cloudLoading
							? clouds.map((cloud, index) => (
									<Skeleton
										width={'100px'}
										height={'100px'}
										key={index}
										animation="wave"
										variant="rectangular"
									/>
							  ))
							: availableClouds.map((cloud) => (
									<li
										style={{ position: 'relative', pointerEvents: 'auto' }}
										key={cloud.id}
										onClick={() => handleCloudSelect(cloud)}
									>
										<div
											className={`select-cloud-list-item ${
												cloud?.connected ? 'connected' : ''
											}`}
										>
											<div className="select-cloud-list-item-cloud">
												{cloud.icon}
											</div>

											{cloud?.connected && (
												<CloudDoneOutlined
													style={{
														position: 'absolute',
														right: '-5.6px',
														top: ' -5.5px',
														width: '23px',
														height: '20px',
														fill: '#4285F4',
														background: '#FFF',
													}}
												/>
											)}
										</div>
										<div className="select-cloud-list-item-cloud">
											{cloud.id}
										</div>
									</li>
							  ))}
					</ul>
				</div>
			)}

			{/* Content for Step 1 */}
			{activeStep === 1 && selectedCloud && (
				<CloudConnectUI selectedCloud={selectedCloud} />
			)}
			{isOpen && (
				<Drawer
					anchor="right"
					open={isOpen}
					onClose={() => {
						setBillData(null);
						setOpen(false);
						setShowBillData(false);
					}}
					onClick={(e) => e.stopPropagation()}
					sx={{
						p: 3,
					}}
				>
					<Paper
						sx={{
							p: 3,
							display: 'flex',
							flexDirection: 'column',
							gap: 2,
						}}
						variant="outlined"
					>
						<Typography
							variant="body1"
							sx={{
								display: 'flex',
								gap: '10px',
								alignItems: 'center',
								color: 'blueviolet',
							}}
						>
							<AutoAwesomeOutlined />
							No Access Keys, No Tokens—Just Upload Your Bill & Let AI Find Your
							Savings!
						</Typography>

						{/* <FormControl component="fieldset">
							<FormLabel component="legend">
								Select Cloud Service to upload the bill
							</FormLabel>
							<RadioGroup
								aria-label="cloud service"
								name=""
								row
								value={selectedService}
								onChange={handleChange}
							>
								<FormControlLabel
									value="aws"
									control={<Radio />}
									label="Amazon Web Services"
								/>
								<FormControlLabel
									value="azure"
									control={<Radio />}
									label="Microsoft Azure"
								/>
								<FormControlLabel
									value="gcp"
									control={<Radio />}
									label="Google Cloud Platform"
								/>
							</RadioGroup>
						</FormControl> */}
						{/* {selectedService && ( */}
						<UploadBill
							setData={setBillData}
							setLoading={setBillLoading}
							cloud={selectedService}
							setShowBill={setShowBillData}
						/>
						{/* )} */}
						{showBillData && (
							<BillAnalysis data={billData} isLoading={billLoading} />
						)}
					</Paper>
				</Drawer>
			)}
		</div>
	);
};

export default SelectCloud;
