import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store, { persistor } from './store';
import { NotificationsProvider } from 'components/Notifications/NotificationContext';
import 'react-toastify/dist/ReactToastify.css';
import { SocketProvider } from 'components/Websocket/SocketProvider';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import * as Sentry from '@sentry/react';
import { PersistGate } from 'redux-persist/integration/react';

// ✅ Initialize Sentry
Sentry.init({
	dsn: process.env.REACT_APP_SENTRY_DSN,
	integrations: [
		Sentry.replayIntegration({
			maskAllText: false,
			blockAllMedia: false,
		}),
	],
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0,
	environment: process.env.NODE_ENV || 'development',
});

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

// ✅ Wrap the App in Sentry.ErrorBoundary
root.render(
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<SocketProvider>
				<NotificationsProvider>
					<Sentry.ErrorBoundary fallback={<p>Oops! Something went wrong.</p>}>
						<App />
					</Sentry.ErrorBoundary>
				</NotificationsProvider>
			</SocketProvider>
		</PersistGate>
	</Provider>
);

// ✅ Register the service worker
serviceWorkerRegistration.register();

// ✅ Send Performance Metrics to Sentry
reportWebVitals((metric) => {
	Sentry.captureMessage(JSON.stringify(metric));
});
