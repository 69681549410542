import { client } from 'api/client';


export class PaymentService {
    static readonly path = 'api/payment';

    static createPayment = (amount: number, name: string) => client.post(`${PaymentService.path}/create-checkout-session`, { params: { amount, name } });
    static verifyPayment = (session_id: string) => client.get(`${PaymentService.path}/verify-session/${session_id}`);
    static paymentHistory = () => client.get(`${PaymentService.path}/history`);

}
