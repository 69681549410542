import { AsyncThunkConfig } from '@reduxjs/toolkit/dist/createAsyncThunk';
import { ReactNode } from 'react';

export interface IUser {
  id: number;
  full_name: string;
  email: string;
  role: UserRole;
  trial: boolean;
  subscription_cycle: string;
  renewed_on: string | null;
  expires_at: string | null;
  started_at: string | null;
  joined_on: string;
  tenant_id: string;
  organisation_name: string
}

export enum UserRole {
  VIEWER = 'viewer',
  ADMIN = 'admin',
  EDITOR = 'editor',
}
export enum DiscoveryStatus {
  COMPLETED = 'completed',
  IN_PROGRESS = 'in_progress',
  FAILED = 'failed'
}

export interface IProjectStats {
  clouds: number;
  discoveries: number;
  integrations: number;
}

export enum DiscoveryType {
  DISK = 'disk',
  COMPUTE = 'compute',
  GKE = 'gke',
  LOGS = 'logging',
}

export enum Provider {
  GCP = 'gcp',
  GKE = 'gke',
  AZURE = 'azure',
  AWS = 'aws'
}

export enum ConnectionType {
  MANUALLY = 'manually',
  AUTOMATICALLY = 'automatically',
  ACCESS_TOKEN = 'access token',
}

export enum ConnectorType {
  ACCESS_TOKEN = 'token',
  FILE = 'file',
}

export interface IConnector {
  id: string;
  name: string;
  type: number;
  project_id: string;
  credential: string;
  created_at: string;
  updated_at: string;
}


export interface ICollection {
  id: string;
}

export interface ITokenScope {
  cluster: boolean;
  disk: boolean;
  instance: boolean;
  pods: boolean;
  logs: boolean;
  database: boolean;
}
export interface IDiscovery {
  organisation: string;
  discovery_id: string;
  id: string;
  token_scope: Record<string, boolean>;
  scope: 'organisation' | 'project';
  date: string;
  project: string;
  user: {
    id: string;
    company: null;
    organisation_id: string;
    updated_at: string;
    email: string;
    full_name: string;
    role: UserRole;
    created_at: string;
  };

  // FE field
  state?: DiscoveryState;
}

export enum DiscoveryState {
  IN_PROGRESS = 'in progress',
  COMPLETED = 'completed',
  FAILED = 'failed',
}

export interface IProjectCreationResponse {
  project_id: string;
  organisation_id: string;
}

export interface ICloud {
  id: string;
  name: string;
  created_at: string;
  projects: number;
  icon?: ReactNode | any | null;
}

export interface ICloudConnectionParam {
  project: string;
  provider: Provider;
  type: ConnectorType;
  value: string;
}

export enum ResourceHealthiness {
  HEALTHY = 'healthy',
  DOWN = 'down',
  SUSPENDED = 'suspended',
}

export interface IResourceLog {
  message: string;
  status: boolean;
  operation_id: string;
  created_at: string;
  id: string;
  user_id: string;
  resource_id: string;
  updated_at: string;
  user: {
    email: string;
    full_name: string;
    role: null;
    updated_at: string;
    id: string;
    company: null;
    created_at: string;
  };
}

export interface ISavingItem {
  action_essentails: {
    non_disruptive: boolean;
    reversible: boolean;
    state: boolean;
  };
  detail: {
    action: ResourceActionType;
    cost_after: number;
    cost_before: number;
    count: number;
    saving_percentage: number;
    size_after: number;
    size_before: number;
    id: string;
    policy_name: Savings;
    type_before?: string;
    type_after?: string;
    after_type?: string;
    memory_after?: number;
    cpu_after?: number;
    after_current_disk?: string;
    last_vm_attached?: number;
  };
  projection_id: string;
  schedule: string;
  cli: {
    command: string;
  };
  teraform: {
    command: string;
  };
  yaml: {
    command: string;
  };
}

export interface INormalizedSaving extends ISavingItem {
  id: Savings;
  name: string;
}

export enum Savings {
  MODERATE = 'moderate',
  LOW = 'low',
  AGGRESSIVE = 'high',
}

export interface IBaseResource {
  id: string;
  object: string;
  discovery_id: string;
  updated_at: string;
  name: string;
  project_id: string;
  created_at: string;
  logs: [];
  savings: Record<ResourceActionType, Record<Savings, ISavingItem>>;
  normalizedSavings: Record<ResourceActionType, INormalizedSaving[]>;
  health: string;
  risk: string;
  max_savings: number;
  cost_impact: number | string;
  saving_percentage: number;
  project: IDiscoveryProject
}

export interface IAutoscaleInstance {
  id: string;
  name: string;
  instance_type: string;
  instance_id: string;
  AutoScalingGroupName: string;
  DesiredCapacity: number;
  MinSize: number;
  MaxSize: number;
  InstanceType: string;
  AvailabilityZone: string;
  LifecycleState: string;
  HealthStatus: string;
}
export interface IVMResource extends IBaseResource {
  normalizedObject: {
    project: string;
    zone: string;
    region: string;
    type: string;
    cpu_usage: number;
    cpu_size: number;
    memory_usage: number;
    memory_size: number;
    labels: Record<string, string>;
    cpu_platform: string;
    last_start_timestamp: string;
    last_stop_timestamp: string;
    last_suspended_timestamp: string;
    current_cloud_cost?: number;
    cuds: ICUDs;
    cross_cloud_pricing?: ICrossCloudPricingResource;
    cpu_utilization: number;
    memory_utilization: number;
    autoscale_group: string | null;
    autoscale_recommendation: Record<string, {
      current_count: number;
      recommended_count: number;
      current_price: number;
      recommended_price: number;
    }>;
    autoscale_instances: IAutoscaleInstance[]
  };
  type: 'compute#instance';
}
export interface IDBResource extends IBaseResource {
  normalizedObject: {
    project: string;
    zone: string;
    type: string;
    region: string;
    version: string;
    cpu_usage: number;
    cpu_size: number;
    memory_usage: number;
    memory_size: number;
    labels: Record<string, string>;
    cpu_platform: string;
    last_start_timestamp: string;
    last_stop_timestamp: string;
    last_suspended_timestamp: string;
    current_disk: string;
    current_disk_size: string;
    dns_name: string;
    current_cloud_cost?: number;
    cuds: ICUDs;
    cross_cloud_pricing?: ICrossCloudPricingResource;
    cpu_utilization: number;
    memory_utilization: number;
  };
  type: 'sql#instance';
}

export interface ILoggingResourceBucket extends IBaseResource {
  normalizedObject: {
    project: string;
    resources: ILoggingResource[];
    retention: number;
    severity: LogsResourcesSeverity[];
    severity_detail: Record<LogsResourcesSeverity, number>;
    volume: number;
    labels?: Record<string, string>;
    current_cloud_cost?: number;
    zone: string;
    region: string;
    cuds?: ICUDs | null;
    cross_cloud_pricing?: ICrossCloudPricingResource;
    cpu_utilization: number;
    memory_utilization: number;
  };
  type: 'storage#log';
}

export interface ILoggingResource {
  name: string;
  severity: LogsResourcesSeverity[];
  type: string;
  volume: number;
  severity_detail: Record<LogsResourcesSeverity, number>;
}
export interface ICUDs {
  Commit1Yr: number;
  Commit3Yr: number;
  Commit1YrSavings: number;
  Commit3YrSavings: number;
}

export interface IVolumeResource extends IBaseResource {
  normalizedObject: {
    project: string;
    zone: string;
    region: string;
    usage_gb: number;
    size_gb: number;
    user: string;
    user_id: string | null;
    source_image: string;
    type: string;
    snapshots: [];
    last_attach_timestamp: string;
    last_detach_timestamp: string;
    labels: Record<string, string>;
    tags: {};
    iops: Record<string, number>[];
    provisioned_iops: null;
    current_cloud_cost?: number;
    cuds: ICUDs;
    cross_cloud_pricing?: ICrossCloudPricingResource;
    cpu_utilization: number;
    memory_utilization: number;
  };
  type: 'compute#disk';
  meta?: string;
}

export interface IGKENodePools {
  disk_size_gb?: number;
  initial_node_count?: number;
  machine_type?: string;
  max_node_count?: number;
  max_pods_per_node?: number;
  name: string;
  status?: number;
  id: string;
}
export interface INamespaces {
  name: string;
  id: string;
  cpu_requested: number;
  memory_requested: number;
}

export interface IGKEResourceMetrics {
  node_count: number;
  cpu_limit: number | null;
  memory_limit: number | null;
  cpu_capacity: number;
  memory_capacity: number;
  cpu_capacity_usage: number;
  memory_capacity_usage: number;
  total_cpu_request: number;
  total_memory_request: number;
  total_cpu_used: number;
  total_memory_used: number;
  total_cpu_allocatable: number;
  total_memory_allocatable: number;
}
export interface IGKENodes {
  name: string;
  status: string;
  version: string;
  namespace: null;
  cpu: number;
  memory: number;
  pods: string;
  cluster: string;
  id: string;
  region: string;
  machine_type: string;
  node_cost_per_cpu: number;
  node_cost_per_memory: number;
  cpu_allocatable: number;
  memory_allocatable: number;
  cpu_request_utilization: number;
  current_cloud_cost: number;
  current_cloud_cost_h: number;
  current_cloud_cost_l: number;
  current_cloud_cost_m: number;
  cuds: any;
  spot_price_monthly: number;
  cpu_unit: string;
  memory_unit: string;
  size_before: string;
  size_after: number;
  cost_before: number;
  cost_after: number;
  savings: Record<ResourceActionType, Record<Savings, ISavingItem>>;
  normalizedSavings: Record<ResourceActionType, INormalizedSaving[]>;
  cpu_requested: number;
  memory_requested: number;
  cpu_used: number;
  memory_used: number;
  memory_utilization: number;
  cpu_utilization: number;
  current_price: number;
  projection_id: string;
  detail: Record<string, unknown>;
  recommended_action: string;
  recommended_cloud_cost: number;
  new_machine_type: string;
  recommended_cpu_allocatable: number;
  recommended_memory_allocatable: number;
  recommended_cpu_request: number;
  recommended_memory_request: number;
  savings_cost: number;
  node_pool: string;
  cli: {
    command: string;
  };
  teraform: {
    command: string;
  };
  yaml: {
    command: string;
  };
}

export interface ClusterConfig {
  current_config: IConfigItem[];
  recommended_config: IConfigItem[];
}

export interface IConfigItem {
  id: string;
  name: string;
  label: string;
  quantity: number;
  total_monthly_cost: number;
  total_hourly_cost: number;
}
export interface IGKEResource extends IBaseResource {
  normalizedObject: {
    node_count: number;
    project: string;
    zone: string;
    type: string;
    region: string;
    version: string;
    cpu_usage: number;
    cpu_size: number;
    memory_usage: number;
    memory_size: number;
    labels: Record<string, string>;
    cpu_platform: string;
    last_start_timestamp: string;
    last_stop_timestamp: string;
    last_suspended_timestamp: string;
    current_disk: string;
    dns_name: string;
    node_pools: IGKENodePools[];
    nodes: IGKENodes[];
    cpu_capacity: number;
    cpu_limit: number;
    memory_capacity: number;
    memory_limit: number;
    cost_after: number;
    cost_before: number;
    saving_percentage: number;
    current_cloud_cost?: number;
    complientpods_percentage?: number;
    cpu_utilization: number;
    memory_utilization: number;
    cuds: ICUDs;
    cluster_config: ClusterConfig;
  };
  type: 'gke#cluster';
  meta?: string;
}

export enum ResourceActionType {
  SCALING = 'scaling',
  COST = 'cost',
  DELETE = 'delete',
  SUSPENDED = 'suspended',
  RIGHTSIZE = 'rightsize',
}

// TO DO get rid of old resources interfaces: IResource, IResourceNormalized
export interface IResource {
  id: string;
  discovery_id: string;
  project_id: string;
  type: string;
  name: string;
  savings?: ISavingItem[];
  maxSaving?: number;
  updated_at?: string;
  created_at?: string;
  logs?: IResourceLog[];
  meta?: string;
  object: string;
  risk: string;
  health: ResourceHealthiness;
  normalizedObject: {
    cuds: ICUDs;
    size_gb?: number;
    project: string;
    severity?: Record<LogsResourcesSeverity, number>;
    total_gb?: number;
    labels: Object[];
    log_resources?: {
      type: {
        name: string;
        severity: LogsResourcesSeverity[];
        retention: number;
        volume: number;
      };
      resources: {
        name: string;
        severity: LogsResourcesSeverity[];
        volume: number;
      }[];
    };
    user: string;
    tags: any;
    zone: string;
    type: string;
  };
  updater?: {
    name: string;
    email: string;
  };
}

export interface IResourceNormalized {
  id: string;
  discovery_id: string;
  project_id: string;
  type: string;
  name: string;
  savings?: ISavingItem[];
  maxSaving?: number;
  updated_at?: string;
  created_at?: string;
  logs?: IResourceLog[];
  meta?: string;
  object: string;
  risk: string;
  health: ResourceHealthiness;
  size_gb?: number;
  project: string;
  severity?: Record<LogsResourcesSeverity, number>;
  total_gb?: number;
  log_resources?: {
    type: {
      name: string;
      severity: LogsResourcesSeverity[];
      retention: number;
      volume: number;
    };
    resources: {
      name: string;
      severity: LogsResourcesSeverity[];
      volume: number;
    }[];
  };
  user: string;
  tags: any;
  zone: string;
  object_type: string;
  updater?: {
    name: string;
    email: string;
  };
  cuds?: ICUDs | null;
}

export enum LogsResourcesSeverity {
  WARNING = 'WARNING',
  INFO = 'INFO',
  CRITICAL = 'CRITICAL',
  ERROR = 'ERROR',
  EMERGENCY = 'EMERGENCY',
  ALERT = 'ALERT',
  NOTICE = 'NOTICE',
  DEBUG = 'DEBUG',
  DEFAULT = 'DEFAULT',
}

export interface IOrganization {
  id: string;
  name: string;
  created_at?: string;
  updated_at?: string;
  user_id?: string;
}

export interface IPaginatedResponse<T> {
  items: T[];
  page: number;
  pages: number;
  size: number;
  total: number;
  projections?: IResourceProjection
}

export interface IProjection {
  count: number;
  name: DiscoveryType;
  potential_cost: number;
  created_at: string;
  savings_rate: number;
  id: string;
  actual_cost: number;
  updated_at: string;
  projection_discoveries: IDiscovery[];
}

export interface IResourceProjection {
  actual_cost: number,
  potential_cost: number,
  savings_percentage: number
}
export interface IProjectionsResponse {
  details: { name: string };
  discovery: {
    stage: string;
    type: DiscoveryType;
  };
  stats: IProjection[];
}

export interface IPriceComparison {
  AWS: number;
  Azure: number;
  GCP: number;
}

export interface IResourceDiscoveryResponse {
  id: string; // discoveryId
  projections: IProjection[];
  resources: IResource[];
}

export interface IOperationLog {
  id: string;
  message: string;
  user_id: string;
  resource_id: string;
  updated_at: string;
  status: boolean;
  operation_id: string;
  created_at: string;
  user: {
    email: string;
    full_name: string;
    role: null;
    updated_at: string;
    id: string;
    company: null;
    created_at: string;
  };
}

export interface ApplyLoggingProjectionParams {
  resources: ApplyLoggingProjectionParamsResource[];
}

export interface ApplyLoggingProjectionParamsResource {
  id: string;
  severity?: string[];
  retention?: number;
  migrate: boolean;
}

export enum Validators {
  REQUIRED = 'required',
  REG_EXP = 'regExp',
  MIN_LENGTH = 'minLength',
  MAX_LENGTH = 'maxLength',
}

export interface IResourceOperationsData {
  id: string;
  name: string;
  operations: number;
  duration: number;
  detail: {
    status: boolean;
    executed_by: string;
    summary: string;
  }[];
  graph_data: {
    date: string;
    successful: number;
    failed: number;
    aborted: number;
  }[];
  resource: {
    name: string;
    type: string;
  };
}

export type ITooltipPlacement =
  | 'top'
  | 'right'
  | 'bottom'
  | 'left'
  | 'right-start'
  | 'bottom-end'
  | 'bottom-start'
  | 'left-end'
  | 'left-start'
  | 'right-end'
  | 'top-end'
  | 'top-start'
  | undefined;

export interface IDiscoveryOverview {
  name: string;
  id: string;
  discovery_id: string;
  instances: number; //vms
  disks: number; //volumes
  databases?: number; //databases
  logs?: number; //logs
  clusters?: number; //cluster
  potential_cost: number;
  actual_cost: number;
  savings_percentage: number;
  action: {
    scaling: number;
    delete: number;
  };
  projects: IDiscoveryProject[];
  cross_cloud_pricing?: ICloudPricing;
}

export interface ICloudPricing {
  aws?: number | null;
  azure?: number | null;
  gcp?: number | null;
}
interface CloudPricing {
  instance_type: string;
  price: number;
}

export interface ICrossCloudPricingResource {
  aws: CloudPricing;
  azure: CloudPricing;
}
export interface IResourceAction {
  scaling: number;
  delete: number;
  cost: number;
}

export interface ScalingData {
  count: number;
  saving: number;
}
export interface IDiscoveryProject {
  id: string;
  name: string;
  workload: number;
  instances: number; //vms
  disks: number; //volumes
  databases: number; //db
  logs: number; //logs
  clusters: number;
  potential_cost: number;
  actual_cost: number;
  savings_percentage: number;
  cross_cloud_pricing?: ICloudPricing;
  discovery_id: string;
  potential_cost_1yr_cuds: number;
  potential_cost_3yr_cuds: number;
  total_3yr_cuds: number;
  potential_cost_spot_prices: number;
  savings_percentage_1yr_cuds: number;
  savings_percentage_3yr_cuds: number;
  total_spot_prices: number;
  savings_percentage_spot_prices: number;
  resource_wise_costing: Record<string, number>;
  action_wise_savings: {
    scaling: ScalingData;
    delete: ScalingData;
    cuds: ScalingData;
  };
  billing: {
    project_costs: { project_id: string, total_cost: number }[];
    projects_daily_costs: any[];
    service_daily_costs: any[];
    service_costs: { service_name: string, total_cost: number }[];
    sku_costs: { sku_name: string, total_cost: number }[];
    sku_daily_costs: any[];
  }
  token_scopes: Record<string, boolean>;
  last_synced_at: string;
  action: IResourceAction;
  log_action: IResourceAction;
  log_resource: IResourceAction;
  cluster_action?: IResourceAction;
  database_action?: IResourceAction;
  instance_action?: IResourceAction;
  disk_action?: IResourceAction;
  cluster_resource?: IResourceAction;
  database_resource?: IResourceAction;
  instance_resource?: IResourceAction;
  disk_resource?: IResourceAction;
  artifact_image_data: IArtifactImageData[];
  ip: number;
  snapshot: number;
  discovery_status: 'completed' | 'failed' | 'in_progress';
  is_unattended: boolean;
}

export interface IPodDetails {
  id: string;
  name: string;
  namespace: string;
  deployment_name: string;
  node: string;
  cpu: number;
  cpu_limit: number;
  memory_limit: number;
  cpu_requested: number;
  memory_requested: number;
  cpu_unit: string;
  cpu_utilization: number;
  memory: number;
  memory_unit: string;
  containers: {
    [key: string]: boolean;
  };
  restarts: number;
  controlled_by: string[];
  QoS: string;
  age: string;
  status: string;
  cluster: string;
  endpoint: string;
  cpu_before: number;
  memory_before: number;
  cpu_after: number;
  memory_after: number;
  actionable: boolean;
  current_cloud_cost: number;
  cost_before: number;
  cost_after: number;
  pod_cost_per_memory: number;
  pod_cost_per_cpu: number;
  projection_id: string;
  recommended_action: string;
  recommended_cloud_cost: number;
  recommended_cpu_request: number;
  recommended_memory_request: number;
  savings_cost: number;
  cli: {
    command: string;
  };
  teraform: {
    command: string;
  };
  yaml: {
    command: string;
  };
}
export interface IPodsNormalizedObject {
  name: string;
  id: string;
  namespace: string;
  deployment_name: string;
  node: string;
  cpu: number;
  cpu_limit: number;
  memory_limit: number;
  cpu_requested: number;
  memory_requested: number;
  cpu_unit: string;
  cpu_utilization: number;
  memory: number;
  memory_unit: string;
  containers: {
    [key: string]: boolean;
  };
  restarts: number;
  controlled_by: string[];
  QoS: string;
  age: string;
  status: string;
  cluster: string;
  endpoint: string;
  cpu_before: number;
  memory_before: number;
  cpu_after: number;
  memory_after: number;
  actionable: boolean;
  current_cloud_cost: number;
  cost_before: number;
  cost_after: number;
  pod_cost_per_memory: number;
  pod_cost_per_cpu: number;
  projection_id: string;
  vcpu?: number;
  vmemory?: number;
  recommended_action: string;
  recommended_cloud_cost: number;
  recommended_cpu_request: number;
  recommended_memory_request: number;
  savings_cost: number;
  cli: {
    command: string;
  };
  teraform: {
    command: string;
  };
  yaml: {
    command: string;
  };
}

export interface IGKEResourceList {
  name: string;
  type: 'node#pods' | 'node#deployments' | 'cluster#daemon_sets';
  is_deleted: false;
  project_id: string;
  created_at: string;
  id: string;
  object: string;
  normalizedObject: IGKENormalizedObject;
  version: string;
  discovery_id: string;
  updated_at: string;
  logs: [];
}

export enum ResourceType {
  LOGGING = 'logging',
  DATABASE = 'database',
  VOLUME = 'volume',
  COMPUTE = 'compute',
  GKE = 'GKE',
}

export enum GKEResourceType {
  PODS = 'pods',
  DEPLOYMENTS = 'deployments',
  DAEMON_SETS = 'daemon',
  STATEFUL_SETS = 'stateful',
  REPLICA_SETS = 'replica',
  JOBS = 'jobs',
  CRON_JOBS = 'cronjobs',
  CONFIG_MAPS = 'configmaps',
  SECRECTS = 'secrets',
  HPA = 'hpa',
  SERVICES = 'services',
  ENDPOINTS = 'endpoints',
  INGRESSES = 'ingresses',
  PVC = 'pvc',
  PERSISTENT_VOLUMES = 'persistentvolumes',
  STORAGE_CLASSES = 'storageclasses',
  NAMESPACE = 'namespace',
  EVENTS = 'events',
  SERVICE_ACCOUNTS = 'serviceaccounts',
  CLUSTER_ROLES = 'cluserroles',
  ROLES = 'roles',
  CRB = 'clusterrolebindings',
  ROLE_BINDINGS = 'rolebindings',
  PSP = 'podsecuritypolicies',
}

export interface IGKENormalizedObject {
  id: string;
  name: string;
  actionable: boolean;
  cpu_requested: number;
  memory_requested: number;
  namespace: string;
  pods: string;
  replicas: number;
  age: string;
  condition: string;
  conditions: string[];
  cluster: string;
  desired: number;
  current: number;
  ready: number;
  node_selector: string;
  desired_replicas: number;
  completions: number;
  schedule: string;
  suspend: boolean;
  next_schedule_time?: string;
  node: string;
  memory: number;
  containers: { key: string; value: boolean | string };
  restarts: number | 0;
  controlled_by: string[];
  QoS: string;
  status: string;
  endpoints: string[];
  type: string;
  ports: string[];
  cluster_ip: string;
  load_balancers: string[];
  rules: string[];
  keys: string;
  labels: { key: string; value: string };
  selector: { key: string; value: string };
  message: string;
  involved_object: { key: string; value: string } | string;
  metrics: string;
  min_pods: number;
  max_pods: number;
  size: string;
  storage_class: string;
  capacity: string;
  claim: string;
  provisioner: string;
  reclaim_policy: string;
  default: string;
  bindings: string;
  privileged: string;
  volumes: string;
  external_ip: string;
  projection_id?: string;
  memory_after: number;
  memory_before: number;
  memory_unit: string;
  cpu: number;
  cpu_after: number;
  cpu_before: number;
  cpu_unit: string;
  cost_after: number;
  cost_before: number;
  current_cloud_cost: number;
  recommended_action: string;
  recommended_cloud_cost: number;
  recommended_cpu_request: number;
  recommended_memory_request: number;
  savings_cost: number;
  zone: string;
  region: string;
  cli: {
    command: string;
  };
  teraform: {
    command: string;
  };
  yaml: {
    command: string;
  };
  detail: {
    action: string;
    cost_after: number;
    cost_before: number;
    cpu_request_utilization: number;
    cpu_requested: number;
    cpu_requested_before: number;
    cpu_used: number;
    memory_requested: number;
    recommended_cpu_request: number;
    recommended_memory_request: number;
    saving_percentage: number;
    size_after: number;
    size_before: number;
    id: string;
    policy_name: string;
    applied: boolean;
    applied_by: string;
  } | null;
}

export interface WorkloadChartStatus {
  running: number;
  pending: number;
  unknown: number;
}

export interface WorkloadChartsData {
  deployments: WorkloadChartStatus;
  daemon_sets: WorkloadChartStatus;
  stateful_sets: WorkloadChartStatus;
  replica_sets: WorkloadChartStatus;
  pods: WorkloadChartStatus;
  jobs: WorkloadChartStatus;
  cron_jobs: WorkloadChartStatus;
}
export interface IPolicyEnginePolicy<T = unknown> {
  name: string;
  id: string;
  value: number;
  policy_id: string;
  updated_at: string;
  user: T | null;
}

export interface Variables<T = unknown> {
  scaling: IPolicyEnginePolicy<T>[];
  delete: IPolicyEnginePolicy<T>[];
}

export interface PolicyEngineResource<T = unknown> {
  actions: {
    name: string;
    applied_on: string;
    variables: Variables<T>;
  };
}

export interface IPolicies {
  disk: PolicyEngineResource[];
  instance: PolicyEngineResource[];
  cluster: PolicyEngineResource[];
  pod: PolicyEngineResource[];
  deployment: PolicyEngineResource[];
  database: PolicyEngineResource[];
  log: PolicyEngineResource[];
  node: PolicyEngineResource[];
}

export interface IReportResource {
  monthly_saving: number;
  annual_saving: number;
  monthly_cost: number;
  annual_cost: number;
  YR_1_cud_saving: number | string;
  YR_3_cud_saving: number | string;
  spot_price: number | string;
}

export interface IReportProjectInfo {
  project_name: string;
  resources_count: number;
  monthly_spending: number;
  monthly_saving: number;
}

export interface IReportRecommendations {
  immediate_actions: string[];
  mid_term_actions: string[];
  long_term_actions: string[];
}

export interface IImplementationComplexity {
  static_ips: 'Low' | 'Medium' | 'High';
  disk_snapshots: 'Low' | 'Medium' | 'High';
  database_snapshots: 'Low' | 'Medium' | 'High';
  disks: 'Low' | 'Medium' | 'High';
  databases: 'Low' | 'Medium' | 'High';
  clusters: 'Low' | 'Medium' | 'High';
}
export interface IReportsData {
  account_name: string;
  id: string;
  monthly_saving: number;
  annual_saving: number;
  monthly_spend: number;
  annual_spend: number;
  resource_count: number;
  savings_percentage: number;
  resources: {
    [key: string]: IReportResource;
    disks: IReportResource;
    instances: IReportResource;
    databases: IReportResource;
    logs: IReportResource;
    cluster: IReportResource;
  };
  time_now: string;
  timeline: string;
  projects: IReportProjectInfo[];
  average_provisioned: number;
  average_requested: number;
  average_used: number;
  action: Record<string, number>;
  resource_name: string;
  resource_type: string;
  project_name: string;
  YR_1_cud_saving: number;
  YR_3_cud_saving: number;
  YR_1_cud_saving_per_month: number;
  YR_3_cud_saving_per_month: number;
  spot_price: number;
  implementation_complexity: IImplementationComplexity;
  strategic_recommendations: IReportRecommendations;
}

export interface IActiveConnections<T = Record<string, number>> {
  expires_in: number;
  id: string;
  name: string;
  status: string;
  discovery_status: boolean;
  type: 'token' | 'file' | string;
  no_of_projects: number;
  organisation_id: string[];
  resources_count: T;
  project_name: string[];
  token_scope: Record<string, boolean> | null;
  stages_info: Record<string, string> | null;
  created_at: string;
  discovery_stage: 'completed' | 'in_progress' | 'failed' | 'pending';
  discovery_stage_by_status: null;
  project_id: string[];
  discovery_id: string;
}

export interface ISavedReports {
  id: string;
  name: string;
  project_name: string;
  created_by: string;
  created_at: string;
  filters: string;
  projects: string[];
  resources: Record<string, number>;
  summary: Record<string, string | number | boolean | any>;
}

export interface IDownloadedReports {
  id: string;
  name: string;
  projects: string[];
  created_by: string;
  created_at: string;
  filters: string;
  resources: Record<string, string>;
  downloaded_by: string;
  downloaded_at: string;
  project_name: string;
  summary: Record<string, string | number | boolean | any>;
}

export interface ICudsLineStats {
  resource_type: string;
  region: string | null;
  current_price: number;
  commit_1_year: number;
  commit_3_year: number;
}
export interface ISpotLineStats {
  resource_type: string;
  region: string | null;
  current_price: number;
  spot_price_per_month: number;
  spot_price_per_hour: number;
}

export interface IWhatsNewProjectSummary {
  project_name: string;
  dicoveries: number;
  resources: string[];
  last_activity: string;
  workloads: number;
  cost: number;
  saving: number;
  actions: number;
  id: string;
  name: string;
}
export interface IWhatsNewResourceSummary {
  count: number;
  cost: number;
  saving: number;
  actions: number;
  resource: string;
  id: string;
  name: string;
}

export interface IWhatsNewDiscoverySummary {
  id: string;
  name: string;
  project: string;
  date: string;
  mode: string;
  resources: string[];
  resources_count
  : Record<string, number>;
  cost: number;
  saving: number;
  actions: number;
  project_billing: {
    service_name: string;
    total_cost: number;
  }[];
  idle_resources: {
    database_snapshot: any[];
    disk_snapshot: any[];
    static_ip: any[];
  }
}

export interface IWhatsNewQuickOptimization {
  id: string;
  name: string;
  action: string;
  cost: number;
  saving: number;
  project: string;
  resource_name: string;
  resource_id: string;
  connector_id: string;
  discovery_id: string;
  account_id: string;
  resource_type: string;
}

export interface IActionDetail {
  scaling: number;
  delete: number;
}
export interface IWhatsNew {
  no_of_discovery: number;
  no_of_projects: number;
  no_of_resources: number;
  savings: number;
  cost: number;
  spending_on_spot_prices: number;
  savings_on_spot_prices: number;
  saving_percentage_on_spot_prices: number;
  cuds_1_year_spending: number;
  cuds_3_year_spending: number;
  savings_1_year_cuds: number;
  savings_3_year_cuds: number;
  saving_percentage_1_year_cuds: number;
  saving_percentage_3_year_cuds: number;
  project_wise_stats: IWhatsNewProjectSummary[];
  resource_wise_stats: IWhatsNewResourceSummary[];
  discovery_wise_stats: IWhatsNewDiscoverySummary[];
  quick_optimizations: IWhatsNewQuickOptimization[];
  organisation_details: string[];
  top_3_savings: Record<string, number>;
  resource_details: Record<string, number>;
  spending: Record<string, number>;
  resource_vise_saving: Record<string, number>;
  saving_percentage: Record<string, number>;
  region_details: string[];
  action_details: { [key: string]: IActionDetail };
}

export interface MyAsyncThunkConfig extends AsyncThunkConfig {
  state?: unknown;
  dispatch?: any;
  extra?: unknown;
  rejectValue?: unknown;
}

export interface Client {
  id: string;
  email: string;
  full_name: string;
  billing_amount: number;
  clouds_connects: number;
  projects_connected: number;
  region: string;
}

export interface MonthWiseRevenue {
  monthly_billing: {
    [month: number]: number;
  };
}

export interface AdminData {
  total_clients: number;
  paid_clients: number;
  trial_clients: number;
  active_clients: number;
  new_clients: number;
  inactive_clients: number;
  total_revenue: number;
  monthly_revenue: number;
  clients: Client[];
  month_wise_revenue: MonthWiseRevenue;
  month_wise_billing: { [month: number]: number };
  region_wise_count: Record<string, number>;
}

export interface ProjectData {
  id: string;
  project_name: string;
  dicoveries: number;
  resources: string[];
  last_activity: string;
}
export interface ProfileData {
  email: string;
  full_name: string;
  location: string;
  subscription: string;
  subscription_amount: number;
}

export interface ClientDetail {
  profile_data: ProfileData;
  transactions_data: any[];
  savings: number;
  projects_data: ProjectData[];
}

export interface ISnapshots {
  id: string;
  name: string;
  creation_timestamp: string;
  source_disk: string;
  status: string;
  disk_size_gb: number;
  cost: number;
  age: string;
}
export interface IRDSSnapshots {
  id: string;
  name: string;
  instance: string;
  startTime: string;
  source_disk: string;
  status: string;
  disk_size_gb: number;
  cost: number;
  age: string;
  kind: string;
  type: string;
}
export interface IIPs {
  id: string;
  name: string;
  created_at: string;
  address: string;
  cost: number;
  age: string;
}

export interface INotification {
  id: string;
  user_id: string;
  type: string;
  message: string;
  status: boolean;
  created_at: string;
  seen: boolean;
}

export interface IBreadcrumb {
  to: string;
  label: string | undefined;
}

export interface ISubFolder {
  id: string;
  name: string;
  cost: number;
  size: number;
  sub_folder_name: string;
}

export interface IArtifactImageData {
  id?: string;
  name?: string;
  location: string;
  repo_name: string;
  repo_size: number;
  repo_cost: number;
  created_at: string;
  sub_folder: ISubFolder[];
  updated_at: string;
}

export interface ISharedLink {
  id: string;
  shared_by_tenant_id: string;
  updated_at: string;
  created_at: string;
  object: {
    links: string[];
    connector_id: string;
    single_project: boolean;
    shared_with_tenant_email: string[];
  };
  expires_at: string; // ISO 8601 format 
  shared_by_tenant_email: string;
}

export interface IIntegration {
  type: string;
  is_active: boolean;
  is_enabled: boolean;
}

export interface IIntegrations {
  id: string;
  type: string;
  project_key: string;
  email: string;
  url: string;
  channel: string;
  default_labels: string;
  is_active: boolean;
  api_token: string;
  is_enabled?: boolean;
}

export interface IBillAnalysisData {
  estimated_total_savings: any;
  high_cost_services: any;
  recommendations_for_cost_reduction: any;
  savings_opportunities: any;
  total_spending: any;
  trends_and_optimization_insights: any
}

// DUMMY INTERFACES FOR NEW DESIGN START
export interface IData {
  id: string;
  inventoryName: string;
  inventoryVersion: string;
  resourceCount: number;
  actionable: string;
  discoveredBy: string;
  discoveryDate: string;
  clouds: string;
  resources: string;
}
//DUMMY INTERFACES FOR NEW DESIGN END
